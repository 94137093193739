<template>
  <span class="ui-data-model">
    <slot
      name="default"
      :model="model"
      :accept="accept"
      :cancel="cancel"
    ></slot>
  </span>
</template>

<script>
export default {
  name: 'ui-data-model',

  props: {
    value: {
      required: false,
      default: null,
    },
  },

  data() {
    return {
      model: {},
      dirtyProperties: [],
    };
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        let incoming = JSON.parse(JSON.stringify(newValue));

        for (let prop in incoming) {
          if (
            incoming.hasOwnProperty(prop) &&
            !this.dirtyProperties.includes(prop)
          ) {
            this.$set(this.model, prop, incoming[prop]);
          }
        }
      },
    },

    model: {
      deep: true,
      handler(newValue) {
        for (let prop in newValue) {
          if (
            newValue.hasOwnProperty(prop) &&
            JSON.stringify(this.value[prop]) !=
              JSON.stringify(newValue[prop]) &&
            !this.dirtyProperties.includes(prop)
          ) {
            this.dirtyProperties.push(prop);
          }
        }
      },
    },
  },

  methods: {
    accept() {
      this.dirtyProperties = [];
      this.$emit('input', JSON.parse(JSON.stringify(this.model)));
    },

    cancel() {
      this.model = JSON.parse(JSON.stringify(this.value));
      this.dirtyProperties = [];
      this.$emit('cancel');
    },
  },
};
</script>